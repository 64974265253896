import React from "react";
import logo from "./vanghetti_logo.svg";
import "./App.css";

const App: React.FC = () => {
  return (
    <div className="vm-pwa">
      <header className="vm-header">
        <img src={logo} className="vm-logo" alt="logo" />
        <h1>Vanghetti srl</h1>
        <p>Sito web in costruzione</p>
      </header>
    </div>
  );
};

export default App;
